<template>
  <v-container>
    <v-row>
      <v-col>
        <v-toolbar
          dense
          color="blue"
          dark
        >
          <h3>Agendar cita</h3>
          <v-spacer />
          <v-btn
            dark
            color="orange"
            small
            @click.prevent="agregar"
          >
            Agendar
          </v-btn>
        </v-toolbar>
      </v-col>
    </v-row>
    
    <v-row>
      <v-col cols="12">
        <v-card
          class="rounded-lg"
          elevation="2"
          color="blue-grey lighten-5"
        >
          <v-row class="mx-1">
            <v-col>
              <h3><v-icon>mdi-store</v-icon>OPORTUNIDAD DE:</h3>
              <v-text-field
                v-model="nomcont"
                label="Contacto"
                readonly
                shaped
                filled
                class="mb-1"
                hide-details
              />
              <v-text-field
                v-model="empresa"
                label="Empresa"
                readonly
                shaped
                filled
                hide-details
              />
            </v-col>
          </v-row>
        </v-card>

        <v-textarea
          v-model="asunto"
          class="mt-6"
          label="Asunto"
          rows="3"
          outlined
          shaped
          auto-grow
          row-height="15"
        />

        <v-textarea
          v-model="obs"
          label="Temas a tratar"
          rows="3"
          outlined
          auto-grow
          row-height="15"
          hide-details
        />
       
        
      
        <v-row>
          <v-col>
            <v-form
              ref="form"
              v-model="valid"
              class="mt-2"
            >
              <v-menu
                ref="menu"
                v-model="menu"
                :close-on-content-click="false"
                :return-value.sync="date"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="date"
                    label="Fecha"
                    :rules="[rules.required]"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  />
                </template>
                <v-date-picker
                  v-model="date"
                  locale="es"
                  no-title
                  scrollable
                >
                  <v-spacer />
                  <v-btn
                    text
                    color="primary"
                    @click="menu = false"
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.menu.save(date)"
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>


              <v-dialog
                ref="dialog"
                v-model="modal2"
                :return-value.sync="time"
                persistent
                width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="time"
                    label="Hora de la cita"
                    prepend-icon="mdi-clock-time-four-outline"
                    readonly
                    v-bind="attrs"
                    :rules="[rules.required]"
                    v-on="on"
                  />
                </template>
                <v-time-picker
                  v-if="modal2"
                  v-model="time"
                  full-width
                >
                  <v-spacer />
                  <v-btn
                    text
                    color="primary"
                    @click="modal2 = false"
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.dialog.save(time)"
                  >
                    OK
                  </v-btn>
                </v-time-picker>
              </v-dialog>

              <v-text-field
                prepend-icon="mdi-map-marker"
                label="Lugar"
                :rules="[rules.required]"
              />
            </v-form>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {mapGetters,} from 'vuex'
export default {
  data (){
    return {
      valid:true,
      time: null,
      menu1: false,
      modal2: false,

      date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      menu: false,
      modal: false,
      menu2: false,

      empresa:'',
      nomcont:'',
      obs:'',
      asunto:'',

      rules: {
        // email: v => !!(v || '').match(/@/) || 'Please enter a valid email',
        // length: len => v => (v || '').length >= len || `Invalid character length, required ${len}`,
        // password: v => !!(v || '').match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*(_|[^\w])).+$/) ||
        //   'Password must contain an upper case letter, a numeric character, and a special character',
        required: v => !!v || 'No dejes este campo vacio',
      },
    }

  },

  computed: {
    ...mapGetters('login',['getdatosUsuario']),
    ...mapGetters('oportunidad',['getOportunidad']),
  },
  
  watch:{
    getOportunidad(){
      // this.limpiar()
      this.actualizarOportunidad()
    },
  },


  created(){

     


    // console.log('router', this.$route.params.cita )

    // let ruta = this.$route.params.cita

    //   //MODO EDITAR
    //   if ( ruta !== undefined ){
    //       this.nModo=2
    //       this.readonly= true

    //       this.CargarInfo(this.$route.params.cita)
    //       this.modo = 'Editar'
    //       this.titulo = 'Editar cita'

    //   } else {
    //       console.log("nuevo")
    //       //VALORES INICIALES ALTA
    //       this.nModo = 1
    //       this.modo  = 'Nuevo'
    //       this.titulo = 'Nueva cita'
    //   }
  },

  methods:{

    // ...mapActions('citas',['addCita']),
    // ...mapActions("snackbar", ["showSnack"]),

    actualizarOportunidad(){
      //Mandar llamar a getoportunidad para cargar los datos de la oportunidad seleccionada
      this.getOportunidad
      console.log(this.getOportunidad)

      this.obs = this.getOportunidad.obs
      this.asunto = this.getOportunidad.asunto
      this.nomcont = this.getOportunidad.nomcont
      this.empresa = this.getOportunidad.empresa
    },


    validate () {
      return this.$refs.form.validate()
    },


    CargarInfo(cita){
      this.empresa  = cita.empresa
      this.obs      = 	cita.obs
      this.nomcont  =	cita.nomcont
    },

    agregar(){
				
      //VALIDAR INFORMACION
		    if(!this.validaInfo){
  		    this.showSnack({
          text: "Debes registrar el asunto, los temas y la fecha",
          color: "orange",
          timeout: 3000,
        });

		      return
		    }


      console.log("fecha",this.fecha)
      console.log("idcontacto", this.idcontacto)

      this.fechaend = this.fecha + ' '+ this.time
      this.fechastart = this.fecha + ' '+ this.time

      var payload = {
        empresa: this.empresa,
        nomcont: 	this.nomcont,
        obs: 	this.obs,
      }

      console.log("payload", payload)

	
	     //MODO NUEVO
      if (this.nModo == 1) {
        //Dedinir db name
        this.$http.post('v2/insert.cita', payload).then(response =>{
			        this.showSnack({
		            text: "Cita Agregada correctamente",
		            color: "success",
		            timeout: 2000,
		          });

			        this.limpiarCita()
	            // setTimeout (() => this.$router.push({name:'catclientes'}),2000);
        }).catch(error=>{
            	// this.snackbar = true ;
	            // this.color="error"
	            // this.text = "Problemas para grabar el cliente"
	            console.log(error)
         			this.showSnack({
		            text: "Problema para agendar Cita."+ error,
		            color: "error",
		            timeout: 2000,
		          });
     
        })
        
      } else {
          
        // EDICION DE UNA CITA.
        this.$http.put('v2/oportunidad.list' + this.idcita, payload).then(response =>{
          this.showSnack({
		            text: "Cita Actualizada correctamente."+ error,
		            color: "error",
		            timeout: 2000,
		          });
          setTimeout (() => this.$router.push({name:'seguimiento1'}),2000);

        }).catch(error=>{
            	this.showSnack({
		            text: "Problema para agendar Cita."+ error.error,
		            color: "error",
		            timeout: 2000,
		          });
          console.log(error)
        })

      }


    },
    limpiarCita(){
      this.empresa = ''
      this.nomcont = ''
      this.obs     = ''
    },
   				
   	regresar(){

      // MODO PANEL
      if (this.$route.name == 'panel') {
        if (this.idcita == undefined){
          console.log("cerrar vista modal y limipiar. ")
          // this.$router.push({name:'panel'}).catch (err => {console.log(err)})
           
        } else {
          console.log("Catalogo regresar")
          this.$router.push({name:'catoportunidades'})
        }
      }

      // MODO CATALOGO
      if (this.$route.name == 'cita' ){
        this.$router.push({name:'catoportunidades'})
      }

      
    },

    // actualizarDatoscontactos() {
    //   this.celular = this.getContacto.celular
    //   this.empresa = this.getContacto.empresa
    //   this.idcontacto = this.getContacto.idcontacto

    //   this.nomcont = this.getContacto.nomcont

    //   this.email = this.getContacto.email
    //   this.numcli = this.getContacto.numcli
        
    //   this.numcont = this.getContacto.numcont
    //   this.sucursal = this.getContacto.sucursal
    //   this.telefono = this.getContacto.telefono
        
    //   this.departamento = this.getContacto.departamento



    // },

    // actualizarNomcont(){
    //   this.nomcont = this.getNewContacto.nomcont
    // },



    // hora(){
    //   var f =new Date();
    //   var hora = f.getHours()
    //   var minuto = f.getMinutes()
    //   var segundo = f.getSeconds()
    //   if(hora < 10){
    //     hora = "0" + f.getHours()
    //   }
    //   if(minuto < 10){
    //     minuto = "0" + f.getMinutes()
    //   }
    //   if(segundo < 10){
    //     segundo = "0" + f.getSeconds()
    //   }
        
    //   return hora + ":" + minuto + ":" + segundo
    // },
	  

  }
}
</script>

<style>

</style>